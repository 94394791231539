export const MODULE = "admin";
export const PRODUCT_FETCH_API = `/${MODULE}/products`;
export const PRODUCT_DETAIL_FETCH_API = `/${MODULE}/product`;
export const PRODUCT_ADD_API = `/${MODULE}/product`;
export const PRODUCT_IMAGE_ADD_API = `/${MODULE}/product/images`;
export const PRODUCT_FEATURE_ADD_API = `/${MODULE}/product/feature`;
export const PRODUCT_VARIANT_ADD_API = `/${MODULE}/product/specification`;
export const PRODUCT_DROPDOWN_API = `/${MODULE}/products/list`;

export const BLOG_FETCH_API = `/${MODULE}/blog/bloglist`;
export const BLOG_DETAIL_FETCH_API = `/${MODULE}/blog`;
export const BLOG_ADD_API = `/${MODULE}/blog`;

export const STATION_FETCH_API = `/${MODULE}/chargingmaps/list`;
export const STATION_DETAIL_FETCH_API = `/${MODULE}/chargingmaps`;
export const STATION_ADD_API = `/${MODULE}/chargingmaps`;

export const BOOKING_ADD_API = `/${MODULE}/bookings`;
export const BOOKING_DETAIL_FETCH_API = `/${MODULE}/bookings`;
export const BOOKING_FETCH_API = `/${MODULE}/bookings/list`;
export const BOOKING_EXPORT_API = `/${MODULE}/export`;

export const VBOOKING_ADD_API = `/${MODULE}/vehiclebooking`;
export const VBOOKING_DETAIL_FETCH_API = `/${MODULE}/vehiclebooking`;
export const VBOOKING_FETCH_API = `/${MODULE}/vehiclebookings/list`;

export const DEALER_ADD_API = `/${MODULE}/dealer`;
export const DEALER_DETAIL_FETCH_API = `/${MODULE}/dealer`;
export const DEALER_FETCH_API = `/${MODULE}/dealers/list`;
export const DEALER_DROPDOWN_API = `/${MODULE}/dealers/dropdown`;

export const PASSWORD_UPDATE_API = `/${MODULE}/changepassword`;

export const RESPONSE_FETCH_API = `/${MODULE}/bookingresponses/list`;
export const RESPONSE_DETAIL_FETCH_API = `/${MODULE}/bookingresponse`;
export const RESPONSE_ADD_API = `/${MODULE}/bookingresponse`;
export const RESPONSE_DROPDOWN_API = `/${MODULE}/response-dropdown`;

export const EXCHANGE_UPSERT_API = `/${MODULE}/estimation`;
export const EXCHANGE_DETAIL_FETCH_API = `/${MODULE}/estimation`;
export const EXCHANGE_FETCH_API = `/${MODULE}/estimation`;

export const EMAIL_UPSERT_API = `/${MODULE}/newsletter`;
export const EMAIL_FETCH_API = `/${MODULE}/newsletters`;
export const EMAILS_EXPORT_API = `/${MODULE}/newsletters/export`;

export const DASHBOARD_STATS_API = `/${MODULE}/stats`;

export const ACTIVATION_UPSERT_API = `/${MODULE}/activation`;
export const ACTIVATION_DETAIL_FETCH_API = `/${MODULE}/activation`;
export const ACTIVATION_FETCH_API = `/${MODULE}/activation/list`;

